import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
//import component pieces
import MiniCaseStudyContentArea from '../templates/mini-case-study-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function ProductPage({ data }) {
  const layoutPieces = data.contentfulMiniCaseStudyPageTemplate.pageComponents

  return (
    <Layout header={data?.contentfulMiniCaseStudyPageTemplate?.header} footer={data?.contentfulMiniCaseStudyPageTemplate?.footer}>
      <MiniCaseStudyContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulMiniCaseStudyPageTemplate?.metadata?.metaDescription} />
    <title>{data?.contentfulMiniCaseStudyPageTemplate?.metadata?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulMiniCaseStudyPageTemplate?.metadata?.ogMetaTitle ? data.contentfulMiniCaseStudyPageTemplate.metadata.ogMetaTitle : data?.contentfulMiniCaseStudyPageTemplate?.metadata?.metaTitle} />
    <meta property="og:description" content={data?.contentfulMiniCaseStudyPageTemplate?.metadata?.ogMetaDescription ? data.contentfulMiniCaseStudyPageTemplate.metadata.ogMetaDescription : data?.contentfulMiniCaseStudyPageTemplate?.metadata?.metaDescription} />
    <meta property="og:type" content={data?.contentfulMiniCaseStudyPageTemplate?.metadata?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulMiniCaseStudyPageTemplate?.slug ? `https://www.entrata.com${data?.contentfulMiniCaseStudyPageTemplate?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulMiniCaseStudyPageTemplate?.metadata?.ogMetaImage?.image ? data?.contentfulMiniCaseStudyPageTemplate?.metadata?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query MiniCaseStudyComponentPieces($slug: String!) {
    contentfulMiniCaseStudyPageTemplate(slug: { eq: $slug }) {
      slug
      header
      footer
      metadata {
        metaTitle
        metaDescription
        ogMetaTitle
        ogMetaDescription
        ogMetaType
        ogMetaImage {
            url
        }
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulProductBanner {
            ...MiniCaseStudyBanner
          }
          ... on ContentfulCenteredText {
            ...HeaderWithText
          }
          ... on ContentfulProductHighlightComponent {
            ...ProductHighlightComponent
          }
          ... on ContentfulClientStatComponent {
            ...ClientStatComponent
          }
          ... on ContentfulFormattedQuote{
            ...FormattedQuote
          }
          ... on ContentfulMiniCaseStudySlider {
            ...CaseStudySlider
          }
        }
      }
    }
  }
`