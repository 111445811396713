import * as React from "react"
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import '../../styles/mini-case-study/mini-case-study-hero.css'

export default function MiniCaseStudyBanner({ layoutPiece }) {
  const backgroundStyle = {
    backgroundImage: `url(${layoutPiece?.image?.image?.url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  return (
    <div style={backgroundStyle}>
      <div className='mini-case-study-hero'>
        <div className='mini-case-study-hero-content'>
          <h1>{layoutPiece?.headlineText}</h1>
          <ReactMarkdown className='markdown'>{layoutPiece?.bodyText?.bodyText}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MiniCaseStudyBanner on ContentfulProductBanner {
    id
    headlineText
    bodyText {
      bodyText
    }
    image {
      fitToFrame
      image {
        gatsbyImageData(width: 900, placeholder: BLURRED)
        url
        description
      }
    }
  }
`
