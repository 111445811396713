import * as React from "react"
import { Suspense } from "react"
import LoadingSpinner from '../components/_loading-spinner'

import MiniCaseStudyBanner from "../components/mini-case-study/mini-case-study-banner"

const HeaderWithText = React.lazy(() => import('../components/mini-case-study/header-with-text'))
const ProductHighlightComponent = React.lazy(() => import('../components/mini-case-study/product-highlight-component'))
const ClientStatComponent = React.lazy(() => import('../components/mini-case-study/client-stat-component'))
const FormattedQuote = React.lazy(() => import('../components/mini-case-study/formatted-quote'))
const MiniCaseStudySlider = React.lazy(() => import('../components/mini-case-study/mini-case-study-slider'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulProductBanner' && <MiniCaseStudyBanner layoutPiece={layoutPiece} key={index}/>,

          layoutPiece.internal.type === 'ContentfulCenteredText' && <Suspense key={index} fallback={<LoadingSpinner />}><HeaderWithText layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulProductHighlightComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><ProductHighlightComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulClientStatComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><ClientStatComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFormattedQuote' && <Suspense key={index} fallback={<LoadingSpinner />}><FormattedQuote layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulMiniCaseStudySlider' && <Suspense key={index} fallback={<LoadingSpinner />}><MiniCaseStudySlider layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ContentArea